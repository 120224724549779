import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { lg, md } from 'config/variables'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { ONADatingProps } from 'components/Props/ona-dating'
import Casebanner from 'components/Casebanner/Casebanner'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Mobileappdrivercontrol from 'components/Mobileappdrivercontrol/Mobileappdrivercontrol'
import Caseresultsec from 'components/Caseresultsec/Caseresultsec'
import Dealingbigdatasec from 'components/Dealingbigdatasec/Dealingbigdatasec'
import { images } from 'config/images'
import Customerexperiencesec from 'components/Customerexperiencesec/Customerexperiencesec'
import Casecontentfullwimg from 'components/Casecontentfullwimg/Casecontentfullwimg'
import Reliabilityinstancessec from 'components/Reliabilityinstancessec/Reliabilityinstancessec'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'

export const ONADatingPage = styled.div`
  .casebanner {
    .container {
      .img-block {
        .banner-img {
          .banner-bg {
            background-color:#fbc2b4;
          }
        }
      }
    }
  }
  .Mobileappscreensec {
    margin-top: 74px;
    ${lg(`
        margin-top:50px;
    `)}
    ${md(`
        margin-top:30px;
    `)}
    .AppScreenMain {
      background:url('${images.onaDatingBg}') no-repeat;
      background-size: 100% 100%;
    }
  }
  .customerExperienceSec  {
    position: relative;
    padding-bottom: 94px;
    ${lg(`   
      padding-bottom: 60px;
    `)}
    ${md(`   
      padding-bottom: 20px;
    `)} 
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 684px;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(to bottom, rgba(252, 253, 255, 0), rgb(247, 247, 247));
      z-index: -1;
    }
  }
  `

const ONADating = memo(props => {
  const { data } = props
  const { location } = props
  let cusExpeItem = [props.data.improvingImg.childImageSharp.fluid]
  const item = props.data.techArchitechDia.childImageSharp.fluid

  let screenListItem = [
    [
      props.data.ihealthAppScreen1.childImageSharp.fluid,
      props.data.ihealthAppScreen2.childImageSharp.fluid,
      props.data.ihealthAppScreen3.childImageSharp.fluid,
      props.data.ihealthAppScreen4.childImageSharp.fluid,
    ],
    [
      props.data.ihealthAppScreen5.childImageSharp.fluid,
      props.data.ihealthAppScreen6.childImageSharp.fluid,
      props.data.ihealthAppScreen7.childImageSharp.fluid,
    ],
    [
      props.data.ihealthAppScreen8.childImageSharp.fluid,
      props.data.ihealthAppScreen9.childImageSharp.fluid,
    ],
  ]
  let productImg = [
    props.data.mixpanel.childImageSharp.fluid,
    props.data.mixpanel2.childImageSharp.fluid,
  ]

  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })
  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="ONA Dating"
        description=""
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/case-studies/ona-dating/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content: 'https://www.simform.com/case-studies/ona-dating/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <ONADatingPage>
        <Casebanner
          bannerdata={ONADatingProps}
          {...props}
          showFullimages={true}
        />
        <Caseintroduction
          caseintrodata={ONADatingProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Mobileappdrivercontrol
            Mobileappdrivercontroldata={ONADatingProps}
            {...props}
          />
        </div>
        {showContent && (
          <>
            <Caseresultsec
              caseresultsecdata={ONADatingProps}
              {...props}
              showImages={false}
            />
            <Dealingbigdatasec
              dealingbigdata={ONADatingProps}
              {...props}
              appScreenList={screenListItem}
              ShowMobileappscreenSec
              showDealingDiagram={false}
            />
            <Customerexperiencesec
              CustomerExperienceProps={ONADatingProps.customerExperienceProps}
              {...props}
              fluid={cusExpeItem}
            />
            <Casecontentfullwimg
              casecontentfullwdata={ONADatingProps.casecontentfullwProps}
              fluid={item}
            />
            <Reliabilityinstancessec
              ReliabilityinstancesData={
                ONADatingProps.ReliabilityinstancesProps
              }
              {...props}
              fluid={productImg}
            />
            <Relatedcaselist relatedcaselistdata={ONADatingProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={ONADatingProps} />
          </>
        )}
      </ONADatingPage>
    </Layout>
  )
})
export default ONADating

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/ona-dating-banner@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen1: file(relativePath: { regex: "/swifts1@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen2: file(relativePath: { regex: "/swifts2@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen3: file(relativePath: { regex: "/swifts3@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen4: file(relativePath: { regex: "/swifts4@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen5: file(relativePath: { regex: "/swifts5@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen6: file(relativePath: { regex: "/swifts6@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen7: file(relativePath: { regex: "/swifts7@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen8: file(relativePath: { regex: "/swifts8@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseScreen9: file(relativePath: { regex: "/swifts9@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 309) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    caseFullimages: file(relativePath: { regex: "/insuranceDetails@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 866) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    sentmapdeashboard: file(
      relativePath: { regex: "/ihealth-app-screen@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dealingdiagram: file(
      relativePath: { regex: "/ihealth-diagram-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen1: file(
      relativePath: { regex: "/ona-dating-app-screen1@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen2: file(
      relativePath: { regex: "/ona-dating-app-screen2@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen3: file(
      relativePath: { regex: "/ona-dating-app-screen3@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen4: file(
      relativePath: { regex: "/ona-dating-app-screen4@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen5: file(
      relativePath: { regex: "/ona-dating-app-screen5@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen6: file(
      relativePath: { regex: "/ona-dating-app-screen6@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen7: file(
      relativePath: { regex: "/ona-dating-app-screen7@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen8: file(
      relativePath: { regex: "/ona-dating-app-screen8@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ihealthAppScreen9: file(
      relativePath: { regex: "/ona-dating-app-screen9@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 246) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    improvingImg: file(
      relativePath: { regex: "/onad-video-calling-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 545) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    techArchitechDia: file(
      relativePath: { regex: "/ona-performance-daigram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mixpanel: file(relativePath: { regex: "/mixpanel-image@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 552) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    mixpanel2: file(relativePath: { regex: "/yozio-image@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 552) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/visibly-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/waypoint-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
