import React from 'react'
import { images } from 'config/images'

export const ONADatingProps = {
  bannerProps: {
    heading: 'ONA Dating',
    bannerPara: 'Enhance your dating experience with an online ‘Matchmaker’',
    BannerAlt: 'ONA Dating Case Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        Americans spend over 2.1 billion USD and 30 hours a month using
        algorithms to find their perfect match. 90% out of US’s 54 million
        singles have tried online dating and one third of them haven’t even gone
        on a date with someone they met online. Few major reasons behind this
        are lack of proper dating advice and absence of a concrete mechanism to
        tackle criminal verification of users.
        <br /> <br /> ONA wanted to start a counterculture of algorithmic dating
        era. They wanted Simform to build a dating cum coaching platform where
        users are coached, advised and manually matched to genuine users, thus
        increasing the chance of converting date into long term relationships
        and not to mention, it ensures the security of the application users
        too.
      </>
    ),
  },
  MobileappdrivercontrolProps: {
    Title: 'Design',
    subTitle: <>Need for a human touch in dating app</>,
    para: (
      <>
        Co-founder of Ona reached out to us for building dating cum coaching
        platform, after getting inspiration from the experience of date-coaching
        his brother who was struggling with different dating apps. Our
        engagement started with a clickable prototype built on Invision to get
        quick initial feedback from real users that not only includes daters but
        also dating experts and coaches. Our product design team were able to
        deliver prototype in less than a month of time which really helped Ona
        team to come up with better ideas and build product as per end user’s
        expectations.
      </>
    ),
  },
  casereSultsecProps: {
    heading: 'Result',
    RightSubHead: 'Smarter Dating for Smarter Generation',
    maincontent: (
      <>
        The final product was developed as a native iOS application using swift.
        We implemented customer focused onboarding processes that helps them
        reach the “aha” moment ASAP. By testing and validation of our user
        flows, we were able to successfully build a community driven customer
        experience that contains - Voice and Video communication, in-app blogs,
        embedded content, content tagging and chat facility.
        <br /> <br /> With a well planned marketing campaign focused around the
        launch of ONA, the application was able to get thousands of users
        easily. The user experience reduced churn, and boosted network effects
        for organic user acquisition. ONA was later covered by all major
        technology publications like TechCrunch, TNW, CNET, to name a few.
      </>
    ),
    // imgURL2x: images.onaDatingAppScreen2x,
    // imgURL: images.onaDatingAppScreen,
    animationvideoURL: images.onaDatingVideo,
  },

  dealingbigdataProps: {
    heading: 'Two Sided Marketplace for dating',
    para: (
      <>
        ONA is two sided marketplace for singles who are looking for a real
        relationship and dating experts who can give dating advice. We can
        consider this as two different apps with different onboarding steps,
        features and purposes. User can read dating advice by different
        specialist and even hire one, via in-app payment. Dating experts provide
        ‘Consulting’ services to the singles, based on the requirement. While
        designing the app, we focused on splendid user experience. We added
        image size criteria for image delivery and APNGs for animation, which
        boosted the performance of the application.
      </>
    ),
  },
  customerExperienceProps: {
    CustomerexperienceDetails: [
      {
        mainTitle:
          'Leveraging Vonage Video APIs for private video based consultation',
        para: (
          <>
            One of the key features of ONA is video and voice calling experience
            where dater can have private one on one session with dating expert.
            <br />
            <br />
            Video and voice calling requires expertise in session creation,
            tackling with reconnecting due to network issues, storing call
            recording on server, audio and video quality fluctuation, latency in
            delivering the audio, etc. <br /> <br />
            Rather than reinventing the wheel, we decided to go for Vonage Video
            API (formerly TokBox OpenTok) and Vonage programmable Voice API
            which is build on top of WebRTC.
            <br />
            <br />
            It allowed us to build and scale a reliable, high quality video
            experience without worrying about hosting, storage or scalability.
          </>
        ),
      },
    ],
  },
  casecontentfullwProps: {
    heading: 'Flexible Architecture for Scalability and app performance',
    para: (
      <>
        With well planned marketing activities by Client at the time of launch,
        we anticipated the sudden increase in user base. We wanted our backend
        architecture and cloud server to efficiently handle this load.
      </>
    ),
    imgCaption:
      'Scalable tech architecture to support large number of users and video sessions at a time',
    bottomPara:
      'We implemented async data transfer from mobile to cloud using high performing Node.js  framework. The scalability of read-write operations was further enhanced by MongoDB. We also made sure that we seamlessly distribute incoming application traffic to multiple EC2 instances with the help of AWS Elastic load balancer to avoid any server downtime. ',
  },
  ReliabilityinstancesProps: {
    trackheading:
      'Utilizing 3rd party services to improve user engagement and safety',
    ReliabilityinstancesDetails: [
      {
        trackbottomPara:
          'There were more than 15+ third party SDKs that needs to be integrated with the application to cater all the needs of security, communication and analytics. ONA is a KPI driven platform, each & every data collected from the activities are used to create actionable insights. Identifying the trends and setting goals is requisite for success of the app. After integrating the app with Mixpanel, the team can now generate cognizance related to users. The data is used to make changes in the app or offering to boost the customer engagement.',
      },
      {
        trackbottomPara:
          'The engagement can’t be sustained further if app doesn’t ensures safety. Going under manual background check was not feasible for this scenario. We utilized Onfido for criminal background check and validation of each and every user who sign up on the platform. Apart from these services we integrated platform with embedd.ly as HTML parser for 3rd party blogs, Zendesk for rapid customer servicing, Yozio for content deeplinking, just to name a few.',
      },
    ],
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'Visibly',
        para: 'get your vision prescription in five minutes',
        className: 'light-lavender',
        link: 'case-studies/visibly/',
      },
      {
        title: 'Waypoint',
        para: 'engaging users with a digital platform at live music festivals',
        className: 'pastel-blue',
        link: 'case-studies/way-point/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
